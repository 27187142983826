import React from 'react';

const Privacy = () => (
    <div className='offerta'>
        <p >
          <h3> ЗГОДА СУБ’ЄКТА ПЕРСОНАЛЬНИХ ДАНИХ </h3>
        </p>
        <p>
         Я, користувач електронних сервісів ТОВ «ТРАСТА», цим документом надаю свою згоду ТОВ «ТРАСТА» (код: 43526873) (надалі – «Володілець персональних даних» та/або «Володілець») на обробку (збирання, реєстрацію, накопичення, зберігання, адаптування, зміну, поновлення, використання і поширення (розповсюдження, реалізацію, передачу), знеособлення, знищення) моїх персональних даних з наступною метою:
         <ul>
           <li>
             забезпечення реалізації відносин у сфері надання фінансових послуг та/або забезпечення реалізації відносин з укладення договорів суб’єктами персональних даних з третіми особами, а також провадження іншої діяльності відповідно до статутних документів та законодавства України;
           </li>
           <li>
            виконання умов договорів, що були/будуть укладені між Користувачем та Володільцем або Користувачем з третіми особами за сприяння Володільця, реалізації та захисту прав сторін за укладеними договорами, що передбачає у тому числі, але не виключно: отримання Користувачем від ТОВ «ТРАСТА» SMS-повідомлень, дзвінків, електронних листів, повідомлень від Сайту в мессенджерах на аккаунт Користувача, а також запис голосового повідомлення/заяви Користувача у разі звернення до служби підтримки тощо;
           </li>
           <li>
            виконання рішень органів державної влади, судових рішень, вимог законодавства України;
           </li>
           <li>
            з метою реалізації інших повноважень, функцій та обов’язків Володільця, що не суперечать законодавству України.
           </li>
         </ul>
        
        </p>

         
        <p>
          Зокрема, надаю свою згоду, на передачу моїх персональних даних третім особам, розпорядникам персональних даних за нижче наведеним переліком – у межах, установлених Законом України «Про захист персональних даних», в тому числі іноземним суб’єктам відносин, пов’язаних з персональними даними, та дозволяю зберігання моїх персональних даних протягом строку, що відповідає меті обробки персональних даних.
        </p>

        <p>
          <h4>Особи, яким передаються персональні дані</h4>
        </p>
        <p>
           За наявності підстав для обробки персональних даних Володілець має право поширювати персональні дані, здійснювати їх передачу третім особам, у тому числі за межі України, іноземним суб'єктам відносин, або надавати доступ до них третім особам, зокрема:
          <ul>
            <li>
              для забезпечення виконання третіми особами своїх функцій або надання послуг Володільцю, зокрема, платіжним системам, банкам, небанківським фінансовим установам, операторам послуг платіжної інфраструктури, аудиторам та іншим контрагентам, якщо такі функції та послуги стосуються діяльності Володільця, здійснюваних ним операцій, або є необхідними для укладання та виконання Володільцем договорів (правочинів), надання Володільцем відповідних послуг Користувачам тощо;
            </li>
            <li>
              при настанні підстав для передачі третім особам персональних даних згідно з законодавством України або відповідно до умов укладених договорів;
            </li>
            <li>
              в інших випадках, передбачених законодавством України та умовами укладених Володільцем договорів, та коли поширення/передача персональних даних є необхідними з огляду на функції, повноваження та зобов’язання Володільця у відповідних правовідносинах.
            </li>
          </ul>
        </p>

        <p>
          <h4> Права суб’єкта персональних даних </h4>
        </p>
        <p>
          Перелік прав суб’єкта персональних даних визначений ст. 8 Закону України «Про захист персональних даних», яка наголошує, що особисті немайнові права на персональні дані, які має кожна фізична особа, є невід’ємними і непорушними.
        </p>
        <p>
          Зокрема, суб’єкт персональних даних має право:
          <ol>
            <li>
             знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх обробки, місцезнаходження або місце проживання (перебування) володільця чи розпорядника персональних даних або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім випадків, встановлених законом;
            </li>
            <li>
              отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб, яким передаються його персональні дані;
            </li>
            <li>
              на доступ до своїх персональних даних;
            </li>
            <li>
             отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи обробляються його персональні дані, а також отримувати зміст таких персональних даних;
            </li>
            <li>
               пред’являти вмотивовану вимогу володільцю персональних даних із запереченням проти обробки своїх персональних даних;
            </li>
            <li>
               пред’являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних будь-яким володільцем та розпорядником персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;
            </li>
            <li>
               на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв’язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;
            </li>
            <li>
               звертатися із скаргами на обробку своїх персональних даних до Уповноважений або до суду;
            </li>
            <li>
              застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;
            </li>
            <li>
               вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди;
            </li>
            <li>
               відкликати згоду на обробку персональних даних;
            </li>
            <li>
               знати механізм автоматичної обробки персональних даних;
            </li>
            <li>
               на захист від автоматизованого рішення, яке має для нього правові наслідки.
            </li>
          </ol>
        </p>

        <p>
        Цим документом засвідчую, що мене повідомлено про мої права як суб’єкта персональних даних, про включення їх до Бази персональних даних, про мету обробки (збирання) моїх персональних даних та про осіб, яким передаються (можуть передаватися) мої персональні дані, тому не вимагаю окремого письмового повідомлення про зазначені відомості.
        </p>
        <p>
        Умови цієї моєї згоди не вимагають окремого повідомлення мене про передачу моїх персональних даних третім особам, розпорядникам персональних даних. Тому прошу не повідомляти мене про передачу моїх персональних даних третім особам, розпорядникам персональних даних, в тому числі іноземним суб’єктам відносин, пов’язаних з персональними даними.
        </p>

        <p> <h4>Підстави для обробки персональних даних </h4></p>
        <p>
           Обробка персональних даних здійснюється за згодою фізичних осіб, а також без такої згоди у випадках, визначених ст. 11 Закону України «Про захист персональних даних»:
           <ul>
             <li>
              для реалізації Володільцем своїх функцій, прав, обов’язків та повноважень, визначених законодавством України, у т.ч. відповідно до Закону України «Про фінансові послуги та державне регулювання ринків фінансових послуг», Закону України «Про запобігання та протидію легалізації (відмиванню) доходів, одержаних злочинним шляхом, фінансуванню тероризму та фінансуванню розповсюдження зброї масового знищення», тощо;
             </li>
             <li>
              з метою укладення та виконання правочину, стороною якого є суб’єкт персональних даних або який укладено на користь суб’єкта персональних даних чи для здійснення заходів, що передують укладенню правочину на вимогу суб’єкта персональних даних;
             </li>
             <li>
               з метою захисту життєво важливих інтересів суб’єкта персональних даних;
             </li>
             <li>
              у разі необхідності захисту законних інтересів володільців персональних даних, третіх осіб, крім випадків, коли суб’єкт персональних даних вимагає припинити обробку його персональних даних та потреби захисту персональних даних переважають такий інтерес.
             </li>
           </ul>
        </p>
        <p>
        З урахуванням викладеного вбачається, що згода суб’єкта персональних даних не є єдиною підставою для здійснення обробки персональних даних, та відповідно така обробка може проводитися й на інших вищенаведених підставах.
        </p>
    </div>

)
export default Privacy